import { Component, OnInit,Input,ViewChild, TemplateRef, ElementRef  } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl, } from '@angular/forms';
import {AuthenticationService } from '../authentication.service'
import { Router ,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr'
import { first, toArray } from 'rxjs/operators';;
import 'rxjs/add/operator/first';
import {MustMatch } from '../_helpers/must-match.validator';

declare const $: any;

@Component({
selector: 'app-signup',
templateUrl: './signup.component.html',
styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {
    fieldValue: any;
    // model: any = {};
    angForm: FormGroup;
    
    columns: [];
    Column = <any>[];
    f= <any>[];
    forms = <any>{};
    registerForm: FormGroup;
    
    @Input() password='';
    @Input() confirmedPassword='';
    
  
    buttonDisable: any;


constructor(
            private authenticationService: AuthenticationService, 
            private router:Router, 
            private toastr:ToastrService, 
            private formBuilder: FormBuilder,
            ){ }

ngOnInit() {
    $('.content-wrapper').addClass('p-0');
    this.buttonDisable = true;
    this.registerForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] ],
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', Validators.required],
            sendemail :['']
        }, {
            validators:[ this.patternValidator( /\d/, 'password','number'),this.patternValidator( /[A-Z]/, 'password','uppercase'),MustMatch('password', 'confirmPassword')]
        });
}
patternValidator(regex: RegExp, controlName,type : string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        // return null if controls haven't initialised yet
        if (!control) {
          return null;
        }

        // return null if another validator has already found an error on the matchingControl
        if (control.errors && !control.errors.mustMatch) {
            return null;
        }
        let valid = regex.test(control.value);

        // set error on matchingControl if validation fails
        if (!valid) {
            
            if(type == 'number'){
                control.setErrors({ number: true });
            }else if(type == 'uppercase'){
                control.setErrors({ uppercase: true });
            }
        } else {
            control.setErrors(null);
        }
    }
  }
markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
    return true;
  }


@ViewChild('stripeFormModal') stripeFormModal: ElementRef

user_register(forms){
    forms = (forms.value);
    this.buttonDisable = false;
    forms.pwd = forms.password;
    forms.fname = forms.name;
    forms.lname = 123;
    forms.cpwd = forms.confirmPassword;
    forms.mobile = 123456789;
    this.authenticationService.user_register(forms).subscribe(response => {
        this.router.navigate(["dashboard"]);
        this.buttonDisable = true;
        return true;
    },err => {
        //handle errors here
        this.buttonDisable = true;
        if(err.status == 500){ 
           this.toastr.error(err.error.ExceptionMessage);
        }
    })
}


phonenumber(inputtxt){

    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(inputtxt.length == 3 || inputtxt.length == 7){

        $('#mobile').val(inputtxt+'-');
    }
    if((inputtxt.value.match(phoneno)))
    {
       
        return true;
    }else{
        // alert('no');
        return false;
    }
  }

  phoneNumberformate(inputtxt){

    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(inputtxt.length == 3 || inputtxt.length == 7){

        $('#primary_phone').val(inputtxt+'-');
    }
    if((inputtxt.value.match(phoneno)))
    {
       
        return true;
    }else{
        // alert('no');
        return false;
    }
  }

}
