import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from './../../shared/shared.service';
import { AuthenticationService } from './../../authentication/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  
  listHidden:boolean =false;
 
  search = '';
  lists : any;
  user: any = {};
  
  constructor(config: NgbDropdownConfig, private router: Router,
    private sharedservice:SharedService,
    private authService:AuthenticationService,
    private route: ActivatedRoute) {
    config.placement = 'bottom-right';
  }


  ngOnInit() {
  

    this.lists =[
      {"id":1,"name":"Apple iPhone 11 64GB Black, 64GB","lowest":"$499","highest":"$649","avg":"$3.23","calcprice":"$599"},
      {"id":2,"name":"Apple iPhone  SE 64GB (PRODUCT)Red","lowest":"$499","highest":"$649","increment":true,"avg":"$3.23","calcprice":"$599"},
      {"id":3,"name":"Apple iPhone 11 128GB white","lowest":"$499","highest":"$649","increment":true,"avg":"$3.23","calcprice":"$599"},
      {"id":4,"name":"Apple iPhone 11 Pro 64GB Space Gray","lowest":"$499","highest":"$649","avg":"$3.23","calcprice":"$599"},
      {"id":5,"name":"Apple iPhone  12 Pro Max 512GB Gold 5G","lowest":"$499","highest":"$649","avg":"$3.23","calcprice":"$599"},
      {"id":6,"name":"Apple iPhone 11 64GB Black, 64GB","lowest":"$499","highest":"$649","avg":"$3.23","calcprice":"$599"},
      {"id":7,"name":"Apple iPhone  SE 64GB (PRODUCT)Red","lowest":"$499","highest":"$649","increment":true,"avg":"$3.23","calcprice":"$599"},
      {"id":8,"name":"Apple iPhone 11 128GB white","lowest":"$499","highest":"$649","increment":true,"avg":"$3.23","calcprice":"$599"},
      {"id":9,"name":"Apple iPhone 11 Pro 64GB Space Gray","lowest":"$499","highest":"$649","avg":"$3.23","calcprice":"$599"},
      {"id":10,"name":"Apple iPhone  12 Pro Max 512GB Gold 5G","lowest":"$499","highest":"$649","avg":"$3.23","calcprice":"$599"},

    ]
     this.initUserInfo();

  }

  initUserInfo(){
    // if(localStorage.getItem('info') && localStorage.getItem('info') != ''){
    //    this.user = JSON.parse(localStorage.getItem('info'));
    // }
    if(this.authService.isLoggedIn()){
        this.sharedservice.GetUser().subscribe(response => {
          this.user = response;
        },err => {
            //handle errors here
          console.log(err.error.Message);
        });
    }
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }

  logout(){
    localStorage.removeItem('auth');
    localStorage.removeItem('user');
    localStorage.clear();
    this.router.navigate(["/authentication/signin"])
  }
  listShow(){
    if(this.search != ''){
      this.listHidden=true;
    }else{
      this.listHidden= false;
    }
   }
   closeSearch(){
     this.search = '';
     this.listHidden=false;
   }
  


}
