import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

declare const $: any;
declare const swal: any;

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ForgotpasswordComponent implements OnInit {

  year = (new Date()).getFullYear();
  forms:any = {};
  forgetPaswordSent = false;
    constructor(
               private _service:AuthenticationService,
               private toastr:ToastrService,
               private router:Router,
               ) {}

    ngOnInit() {
        document.querySelector('.content-wrapper').classList.add('p-0');
    }

    forgetPasword(forms){
        this._service.forgetPasword(forms).subscribe(response => {
            this.forgetPaswordSent = true;
            //this.router.navigate(["dashboard"])
        },err => {
            //handle errors here
            //this.forgetPaswordSent = true; 
            this.toastr.error(err.error.error_description);

        })
    }
}
