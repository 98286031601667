import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup,  FormBuilder,  Validators, NgForm } from '@angular/forms';
import { SubscriptionService } from './../subscription.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-newsubscription',
  templateUrl: './newsubscription.component.html',
  styleUrls: ['./newsubscription.component.scss']
})
export class NewsubscriptionComponent implements OnInit {

  constructor(
    private router:Router,
    private toastr:ToastrService,
    private formBuilder: FormBuilder,
    private service:SubscriptionService,
    ) { }

  step1 : any;
  step2 : any;
  step3 : any; 

  buttonDisable: any;

  subscription : any = {
    Fee:'',
  } ;
  subscription1 : any ;
  subscription2 : any ;

  forms: FormGroup;

  ngOnInit(): void {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;

    this.forms = this.formBuilder.group({
      Company: [''],
      Address: [''],
      Address2: [''],
      City: [''],
      Country: [''],
      PostalCode: [''],
      product: [100],
      period: ['monthly'],
      payment_type: ['paypal'],
      CardNumber: [''],
      CardMonth: [''],
      CardCvc: [''],
    });

  }
  ngAfterContentInit(){
    this.selectPrice(100)
  }

  selectPeriod(v){
    if (v == 1){
      this.subscription = this.subscription1;
      document.querySelector(".first-checkbox").classList.add("active");  
      document.querySelector(".second-checkbox").classList.remove("active");
    }else if(v == 2){
      this.subscription = this.subscription2;
      document.querySelector(".second-checkbox").classList.add("active");  
      document.querySelector(".first-checkbox").classList.remove("active");
    }
  }

  selectPaymentType(v){
    if (v == 1){
      document.querySelector(".payment-box-1").classList.add("active");  
      document.querySelector(".payment-box-2").classList.remove("active");
    }else if(v == 2){
      document.querySelector(".payment-box-2").classList.add("active");  
      document.querySelector(".payment-box-1").classList.remove("active");
    }
  }

  selectPrice(price){
    this.buttonDisable = true;
      this.service.getSubscription(price).subscribe(response => {
        this.buttonDisable = false;
        if(this.forms.value.period == 'annualy'){
          this.subscription = response[1];
        }else{
          this.subscription = response[0];
        }
        this.subscription1 = response[0];
        this.subscription2 = response[1];

        document.querySelector(".price-box-1").classList.remove("active");
        document.querySelector(".price-box-2").classList.remove("active");
        document.querySelector(".price-box-3").classList.remove("active");
        document.querySelector(".price-box-4").classList.remove("active");

        if(price == 100){
          document.querySelector(".price-box-1").classList.add("active");  
        }else if(price == 1000){
          document.querySelector(".price-box-2").classList.add("active");  
        }else if(price == 2500){
          document.querySelector(".price-box-3").classList.add("active");  
        }else if(price == 5000){
          document.querySelector(".price-box-4").classList.add("active");  
        }

      },err => {
        this.buttonDisable = false;
          //handle errors here
          this.toastr.error(err.error.MessageDetail);
      });
      
      
  }

  nextStep(step){
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    if(step == 1){
      this.step1 =true;
    }else if(step == 2){
      this.step2 = true;
    }if(step == 3){
      this.step3 =true;
    }
  }

  saveBilling(){
    this.buttonDisable = true;
    var data : any = {};
    data.Company = this.forms.value.Company;
    data.Address = this.forms.value.Address;
    data.Address2 = this.forms.value.Address2;
    data.City = this.forms.value.City;
    data.CountryCode = this.forms.value.Country;
    data.PostalCode = this.forms.value.PostalCode;
    data.Phone = '1234567890';
    this.service.saveBilling(data).subscribe(response => {
      this.nextStep(3);
      this.buttonDisable = false;
    },err => {
      this.buttonDisable = false;
        //handle errors here
        this.toastr.error(err.error.Message);
    });

  }

  submitForm(){
    this.buttonDisable = true;
    if(this.forms.value.payment_type == 'paypal'){
      var data : any = {};
      data.subscriptionId = this.subscription.Id;
      this.service.payPalSubscribe(data).subscribe(response => {
        window.location.href = response.Link;
        this.buttonDisable = false;
      },err => {
        this.buttonDisable = false;
          //handle errors here
          this.toastr.error(err.error.Message);
      });
    }else{
      this.buttonDisable = false;
      console.log(this.forms.value)
    }
  }

}
