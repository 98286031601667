import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionComponent } from './collection/collection.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { RouteGuard } from  './../route.guard';
import { ViewComponent } from './view/view.component';
import {MatRippleModule} from '@angular/material/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {
      path: 'collection',
      component: CollectionComponent,
      canActivate: [RouteGuard]
  },
  {
    path: 'view',
    component: ViewComponent,
    canActivate: [RouteGuard]
}, 

 
];


@NgModule({
  declarations: [CollectionComponent, ViewComponent, ],
  imports: [
    NgbModule,
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,ReactiveFormsModule,
    MatRippleModule,
    NgxPaginationModule,

  ]
})
export class CollectionsModule { }
