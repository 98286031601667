import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import * as Rx from "rxjs";
import { environment as Constants } from './../../environments/environment';
import { Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private url = '';

  userInfo = new Rx.BehaviorSubject({});
  user:any = this.userInfo.asObservable();

  constructor(private http: HttpClient,
    private router: Router,) { 
    this.url = Constants.API_URL
  }

    isLoggedIn(failed_redirect = false,success_redirect = false){
        if(!localStorage.getItem('auth') || localStorage.getItem('auth') == '' || localStorage.getItem('auth') == undefined){
          if(failed_redirect){
            this.router.navigate(["/authentication/signin"]);
          }
            return false;
        }else{
            if(success_redirect){
              this.router.navigate(["/dashboard"]);
            }
            return true;
        } 
    }

    userIsNotLogin(){
      this.isLoggedIn(false,true);
    }

    login(data){
      const params = new HttpParams({
        fromObject: {
          grant_type: 'password',
          username: data.email,
          password: data.password,
        }
      });

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          })
        };
        data.grant_type = 'password';
        var result :any;
        result =  this.http.post(this.url+"token",params,httpOptions);
        return result;
    }

    register(data){
            return this.http.post(this.url+"callback/organizationselfregister",data).map(res => res["result"]);
    }

    logout(userId,admiId){
            return this.http.get(this.url+"auth/logout/"+userId+"/"+admiId).map(res => res["result"]);
    }
   
    user_register(data,files=null){

      data.Firstname = data.fname;
      data.Lastname = data.lname;
      data.Email = data.email;
      data.password = data.pwd;
      data.Street = data.lname;
      data.Plc = data.lname;
      data.City = data.lname;
      data.Country = data.lname;
      data.TaxId = data.lname;
      
      var formData: FormData = new FormData();
      var dataArr = Object.keys(data).map(function(key) {
          return [key, data[key]];
      });

      if(files != null && files.length > 0){
          let index = 0;
          for (let file of files) {
              formData.append('image['+index+']', file, file.name);
              index ++ ;
          }
      } 
      dataArr.forEach((element) => {
      formData.append(element[0], element[1], )
      });

      let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');

      return this.http.post<any>(this.url+"api/User",data,{ headers: reqHeaders }).map(res => res);
      // return this.http.post(this.url+"callback/contact",data).map(res => JSON.parse(res['_body'])["result"]);
  }
    resetPassword(data){
            return this.http.post<any>(this.url+"api/User/CompleteResetPassword",data).map(res => res);
    }

    forgetPasword(data){
        return this.http.post<any>(this.url+"api/User/ResetPassword",data).map(res => res);
    }
}
