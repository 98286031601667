import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../authentication/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    document.querySelector('.content-wrapper').classList.add('p-0');
    
    this.authService.userIsNotLogin();
  }

}
