import { Component, OnInit } from '@angular/core';
import { NgbModal,NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators ,FormControl, } from '@angular/forms';
import { CustomValidators } from '../../shared/custom-validators';
import { SharedService } from '../../shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(
    private modalService: NgbModal ,
    private formBuilder:FormBuilder,
    private router:Router,
    private toastr:ToastrService,
    private service:SharedService,
    ) { }

  Changeform: FormGroup;
  Profileform: FormGroup;
  isShow : boolean=true;
  modal : NgbModalRef ;
  user : any = {};
  profile:boolean;

  buttonDisable : boolean = false;

  ngOnInit(): void {
    this.Changeform = this.formBuilder.group({
        password:['',Validators.required],
    
      
        newpassword: ['',  Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
        
          // check whether the entered password has a special character
        
          Validators.minLength(8)
        ])],
        cpassword: ['',  Validators.compose([Validators.required])]
      },{
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    
    );

    this.Profileform =  this.formBuilder.group({
      FirstName:['',Validators.required],
      LastName:['',Validators.required],
      Email: ['', [Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] ],
      Company:[''],
      Street:[''],
      City:[''],
      Country:[''],
      PostalCode:[''],
    });

    this.initUserInfo();

  }

  initUserInfo(){
    this.service.GetUser().subscribe(response => {
      this.user = (response);
    },err => {
        //handle errors here
      console.log(err.error.Message);
    });
  }


  openMediumModal( mediumModalContent ) {
      this.resetfunc();
      this.modal = this.modalService.open( mediumModalContent );
  }

  markFormGroupTouched(formGroup: FormGroup) {
    this.showheading();
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
    return true;
  }
  
  showheading(){
    this.isShow= false;

  }
  resetfunc(){
    this.Changeform.reset();
    this.isShow=true;
  }

  onChangePass(){
    this.isShow=true;
    this.buttonDisable = true;
    var data : any = {};
    data.CurrentPassword = this.Changeform.value.password;
    data.NewPassword = this.Changeform.value.newpassword;
      this.service.ChangePassword(data).subscribe(response => {
        this.buttonDisable = false;
        this.modal.close();
      },err => {
        this.buttonDisable = false;
          //handle errors here
          this.toastr.error(err.error.Message);
      });

  }

  ProfileUpdate(){
    this.buttonDisable = true;
    let data : any  = {};
    data = this.Profileform.value;
    data.CompanyName = this.Profileform.value.Company;
    data.Street = this.Profileform.value.Street;
    data.Plc = this.Profileform.value.PostalCode;
    data.City = this.Profileform.value.City;
    data.Country = this.Profileform.value.Country;
    data.TaxId = this.user.TaxId;
    this.service.ProfileUpdate(data).subscribe(response => {
      this.buttonDisable = false;
      this.modal.close();
      this.user = data;
      data = JSON.stringify(data);
      localStorage.setItem('info', data);
      this.toastr.success('Profile updated');
    },err => {
      this.buttonDisable = false;
        //handle errors here
        this.toastr.error(err.error.Message);
    });
  }

  openProfileModal( mediumModalContent ,type = 0) {
      if(type == 1){
        this.profile = false;
      }else{
        this.profile = true;
      }
      this.Profileform.setValue({
        FirstName: this.user.FirstName,
        LastName: this.user.LastName,
        Email : this.user.Email,
        Company:this.user.CompanyName,
        Street:this.user.Street,
        City:this.user.City,
        Country:this.user.Country,
        PostalCode:this.user.Plc,
      });
      this.modal = this.modalService.open( mediumModalContent, { size : 'sm'} );
  }
}
