import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';

import { AuthenticationService } from './authentication/authentication.service';
import { SubscriptionModule } from './subscription/subscription.module';
import { CollectionsModule } from './collections/collections.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { SettingModule } from './setting/setting.module';

import { HttpClientModule,HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpInterceptorService } from './http-interceptor';
import { HttpModule } from '@angular/http';
import { ToastrModule } from 'ngx-toastr';

import {MatRippleModule} from '@angular/material/core';
import { ChartModule } from 'angular-highcharts';



import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { HomenavbarComponent } from './shared/homenavbar/homenavbar.component';
import { HomefooterComponent } from './shared/homefooter/homefooter.component';

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
} 

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    SpinnerComponent,
    ContentAnimateDirective,
    HomeComponent,
    PricingComponent,
    HomenavbarComponent,
    HomefooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    ChartModule,
    MatRippleModule,
    SubscriptionModule,
    CollectionsModule,
    AuthenticationModule,
    SettingModule,
  ],
  providers: [
    ThemeService,
    AuthenticationService,
    HttpInterceptorService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: LocationStrategy , useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
