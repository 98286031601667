import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouteGuard } from  './../route.guard';




const routes: Routes = [
  {
      path: 'settings',
      component: SettingsComponent,
      canActivate: [RouteGuard]
  },
 
];

@NgModule({
  declarations: [SettingsComponent],
  imports: [
    CommonModule,    
    RouterModule.forChild(routes),
    FormsModule,ReactiveFormsModule

  ]
})
export class SettingModule { }
