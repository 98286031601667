import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { FormBuilder, FormGroup, Validators,FormControl, FormArray} from '@angular/forms';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  views : any;

  collectionLink = 'https://pricebot.com/collection/13Ehz2k-41f1';

  currentPage = 1;
  itemsPerPage = 12;

  private fieldArray: Array<any> = [];
  private newAttribute: any = {};

  buttonDisable:boolean = false;

  forms : FormGroup;

/*  FieldsValue = new FormArray([
                  new FormGroup({
                    name: new FormControl(''),
                    value: new FormControl('')
                  })
                ]);*/
  FieldsValue:any;

                 

  constructor(
    private modalService: NgbModal,
    private toastr:ToastrService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {

    this.buttonDisable = true;
    this.forms = this.formBuilder.group({
                     Title :[''],
                     EAN :[''],
                     Fields :[false],
                     FieldsValue : new FormArray([
                                    new FormGroup({
                                      name: new FormControl(''),
                                      value: new FormControl('')
                                    })
                                  ]),
                     AdvancedSettings :[false],
                     CalculationTheme :[''],
                     MinPrice :[''],
                     PurchasePrice :[''],
                     MaxPrice :[''],
                  });
    this.FieldsValue = this.getFieldsValue() as FormArray;                   

    this.views=[
      {"id":1,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":2,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":3,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":4,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":5,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":6,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":7,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":8,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":9,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":10,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":4,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":5,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":6,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":7,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":8,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":9,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":10,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":4,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":5,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":6,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":7,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":8,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":9,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":10,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":4,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":5,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":6,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":7,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      {"id":8,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":9,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","avg":"$6.57","calcprice":"$39.25"},
      {"id":10,"description":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor Wa...","lowest":"$34.20","highest":"$42.59","increment":true,"avg":"$6.57","calcprice":"$39.25"},
      
    ]
  }

  openSmallModal( smallModalContent ) {
    this.modalService.open( smallModalContent , { size : 'md',windowClass: 'share-modal'} );
  }

  copyLink(){
    var val = this.collectionLink;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Link copyied successfully');
  }

  openModal( smallModalContent ) {
    this.modalService.open( smallModalContent , { size : 'sm' } );
  }

  addFieldValue() {
      this.fieldArray.push(this.newAttribute)
      this.newAttribute = {};

      //this.FieldsValue.push(new FormControl(''));
      this.FieldsValue.push(
        new FormGroup({
          name: new FormControl(''),
          value: new FormControl('')
        })
      );
  }

  deleteFieldValue(index) {
      this.fieldArray.splice(index, 1);
  }

  addProduct(forms){
    console.log(forms.value);
  }

  getFieldsValue() {
    return this.forms.get('FieldsValue') as FormArray;
  }


}
