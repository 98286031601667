import { Component, OnInit } from '@angular/core';
import { ChartType,ChartOptions} from 'chart.js';
import { MultiDataSet, Label,Color } from 'ng2-charts';
import { ChartModule } from 'angular2-highcharts';
import { Chart, HIGHCHARTS_MODULES} from 'angular-highcharts';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from './../subscription/subscription.service';

declare const $: any;
declare const jquery: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  foods :any;
  public doughnutChartLabels: Label[] = ['Above percentile', 'Below percentile', 'Not crawlable','Exact match'];
  public doughnutChartData: MultiDataSet = [
    [423, 84, 2743,893],
  ];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartLegend = false;

  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    cutoutPercentage:80
  };

  public barChartColors: Color[] = [
    { backgroundColor: ['#F66B26','#8201C3','#85B0FE','#012F84']},
  ]

  selected: string;
  chart: Chart;
  chartReady = false;
  series : any;
  subsciption: any;
  products : any;

  constructor(
    private service: SubscriptionService,
    private toastr:ToastrService,
  ) { }

  ngOnInit() {
    this.selected = '30';
    this.foods = [
      {value: '30', viewValue: 'Last 30 days '},
      {value: '15', viewValue: 'Last 15 days '},
      {value: '7', viewValue: 'Last 7 days '}
    ];
    this.init();
    document.querySelector('.content-wrapper').classList.remove('p-0');
  }
  ngAfterViewInit() {
  }
  date: Date = new Date();

  init() {
    this.series = [{
      name: '22-Pound Roaster Oven With…',
      type:'line',
      color:'#F66B26',
      data: [1,2,3,4,5,6,7,8,9,8,7,6,5,4,1,2,3,4,5,6,7,8,9,10,9,8,7,6,5,4],
    },{
      name: 'Fitness Tracker HR, Activity...',
      type:'line',
      data: [10,9,8,7,6,5,4,3,2,1,0,-1,-2,-3,-4,-5,-6,-7,-8,-9,-8,-7,-6,-4,-1,2,3,4,5,4],
      color:'#012F84'
    }];

    let chart = new Chart({
      chart: {
        type: 'line'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },

      yAxis:{
        title: {
          text : ''
        },
        min:-10,
        max:10,
        tickInterval: 10,
        labels:{
          formatter: function () {
               return this.value + '%';
            }
        },
         gridLineWidth: 1,
        lineWidth: 1,
        gridLineDashStyle:'LongDash',
        lineColor: '#9c9fa6',
        tickColor: '#9c9fa6'
      },
      xAxis: {
          categories: ['17','18','19','20','21','22','23','24','25','26','27','28','29','30','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16'],
          title: {
            text : 'OCTOBER'
          },
          gridLineWidth: 1,
          lineWidth: 1,
          gridLineDashStyle:'LongDash',
          lineColor: '#9c9fa6',
        tickColor: '#9c9fa6'
      },

      legend: {
          align: 'left',
          verticalAlign: 'top',
          x: 0,
          y: 0,
          itemMarginTop: 10,
          itemMarginBottom: 10
      },

      series: this.series
    });
    this.chart = chart;
    this.chartReady = true;

    //chart.ref$.subscribe(console.log);
   
    this.getActiveSubsciption();

    this.products =  [
                        { "id":1 ,"name":"22-Pound Roaster Oven With Removable 3-Bin Buffet Server, 18-Quart, S…","description":"This means that your delivery drivers may ask for delivery","increment":true,"percent":"+15%","products":"12 products","low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":2 ,"name":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor, Wa…","description":"High quality products","increment":true ,"percent":"+2.4%","products":"64 products","low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":3 ,"name":"COWIN E7 Active Noise Cancelling Headphones Bluetooth Headphone…","description":"Only summer sale", "percent":"-3%","products":"32 products","low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":4 ,"name":"New GoPro HERO9 Black - Waterproof Action Camera With Front LCD …","description":" ","increment":true,"percent":"+3.4%","products":"12 products" ,"low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":5 ,"name":"Edifier S2000pro Powered Bluetooth Bookshelf Speakers - Near-Field A…","description":"Products above $100","percent":"-2.9%","products":"12 products","low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25" },
                        { "id":6 ,"name":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor, Wa…","description":"Playstation and xBox" ,"increment":true,"percent":"+15%","products":"3 products","low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":1 ,"name":"22-Pound Roaster Oven With Removable 3-Bin Buffet Server, 18-Quart, S…","description":"This means that your delivery drivers may ask for delivery","increment":true,"percent":"+15%","products":"12 products","low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":2 ,"name":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor, Wa…","description":"High quality products","increment":true ,"percent":"+2.4%","products":"64 products","low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":3 ,"name":"COWIN E7 Active Noise Cancelling Headphones Bluetooth Headphone…","description":"Only summer sale", "percent":"-3%","products":"32 products","low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":4 ,"name":"New GoPro HERO9 Black - Waterproof Action Camera With Front LCD …","description":" ","increment":true,"percent":"+3.4%","products":"12 products" ,"low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":5 ,"name":"Edifier S2000pro Powered Bluetooth Bookshelf Speakers - Near-Field A…","description":"Products above $100","percent":"-2.9%","products":"12 products" ,"low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        { "id":6 ,"name":"Fitness Tracker HR, Activity Tracker Watch with Heart Rate Monitor, Wa…","description":"Playstation and xBox" ,"increment":true,"percent":"+15%","products":"3 products","low":"$34.20","high":"$42.59","avg":" $3.23","sale_price":"$40.25"},
                        
                      ];
  }

  getActiveSubsciption(){
    this.service.getActiveSubsciption().subscribe(response => {
      //console.log(response)
      this.subsciption = response;
      if(response != null)
      this.subsciption.percent = (response.ContingentInUse / response.Contingent) * 100;
    },err => {
        //handle errors here
        this.toastr.error(err.error.Message);
    });
  }

  seriesClick(i){
    var  t = '.highcharts-series-'+i;
    
    $(t).click();
    
    if( $(t).hasClass('highcharts-legend-item-hidden') ){
      $('.hero-series-'+i).addClass('hero-inactive');
    }else{
      $('.hero-series-'+i).removeClass('hero-inactive');
    }

  }
  addSerie() {
    let t : any =  {
      name: 'Line ' + Math.floor(Math.random() * 10),
      type:'line',
      color:this.getRandomColor(),
      data: [
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
        Math.floor(Math.random() * 10),
      ]
    };
    this.chart.addSeries(t ,true,true);

    this.series.push(t);

  }

  removeSerie(i) {
    this.chart.removeSeries(i);
    this.series.splice(i, 1);
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getLighter(color){
     color = this.shadeColor(color,0.4);
    return color;
  }

  shadeColor(hex, lum) {

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;
  
    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00"+c).substr(c.length);
    }
  
    return rgb;
  }
  

}
