import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouteGuard } from  './route.guard';
import { PricingComponent } from './pricing/pricing.component';

const routes: Routes = [
  { path: '', component: HomeComponent }, 
  //{ path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [RouteGuard] },
  {path :'pricing', component: PricingComponent } ,
  { path: 'error-pages', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: 'authentication', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)},
  { path: 'collections', loadChildren: () => import('./collections/collections.module').then(m => m.CollectionsModule),canActivate: [RouteGuard] },
  { path: 'setting',loadChildren: () =>import('./setting/setting.module').then(m => m.SettingModule),canActivate: [RouteGuard] },
  { path: 'subscription', loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule),canActivate: [RouteGuard] },
  { path: '**', redirectTo: 'error-pages/404'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false } )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
