import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../authentication/authentication.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  // datas: any;

  constructor(
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    document.querySelector('.content-wrapper').classList.add('p-0');
    this.authService.userIsNotLogin();
    
  }

  selectPeriod(v){
    if (v == 1){
      document.querySelector(".first-checkbox").classList.add("active");  
      document.querySelector(".second-checkbox").classList.remove("active");
    }else if(v == 2){
      document.querySelector(".second-checkbox").classList.add("active");  
      document.querySelector(".first-checkbox").classList.remove("active");
    }
  }

  selectSize(v){
      document.querySelector(".size-1").classList.remove("active");
      document.querySelector(".size-2").classList.remove("active");
      document.querySelector(".size-3").classList.remove("active");
      document.querySelector(".size-4").classList.remove("active");
    if (v == 1){
      document.querySelector(".size-1").classList.add("active");  
    }else if (v == 2){
      document.querySelector(".size-2").classList.add("active");  
    }else if (v == 3){
      document.querySelector(".size-3").classList.add("active");  
    }else if (v == 4){
      document.querySelector(".size-4").classList.add("active");  
    }
  }

  //   this.datas=[
  //     {"id":1,"title":"BASIC","amt1":"10","subHeading":"100 tracing products"},
  //     {"id":1,"title":"ESSENTIAL","amt1":"10","subHeading":"100 tracing products"},
  //     {"id":1,"title":"PRO","amt1":"10","subHeading":"100 tracing products"},
  //     {"id":1,"title":"Basic","amt1":"10","subHeading":"100 tracing products"},

  //   ]
  // }

}
