import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { FormGroup,  FormBuilder,  Validators, NgForm } from '@angular/forms';


declare const $: any;

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SigninComponent implements OnInit {
    userId:any;
    user = <any>{};
    params = <any>{}; 
    form:any = {};
    year = (new Date()).getFullYear();
    forms:any = {};
    buttonDisable: any;
    userlogOut: string;
       constructor(private _service:AuthenticationService,private router:Router,private toastr:ToastrService,private fb: FormBuilder) { 
       }

    ngOnInit() {
        this.buttonDisable = true;
        if(this._service.isLoggedIn()){
            this.router.navigate(["dashboard"]);
          }
          document.querySelector('.content-wrapper').classList.add('p-0');
    }

    login(forms){
    
        this.buttonDisable = false;
        this._service.login(forms).subscribe(response => {
            if(response != undefined ||   response.status == true){
                this.buttonDisable = true;

                localStorage.setItem('auth', response.access_token);
                response = JSON.stringify(response);
                localStorage.setItem('user', response);
                //this.toastr.success(response.message);
                this.router.navigate(["dashboard"]);
            }else{
                this.buttonDisable = true; 
                //this.toastr.error(response.message);
                return false;
            }
        },err => {
            //handle errors here
            this.buttonDisable = true; 
            this.toastr.error(err.error.error_description);

        });
    }
}
