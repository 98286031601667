import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SubscriptionComponent } from './subscription/subscription.component';
import { NewsubscriptionComponent } from './newsubscription/newsubscription.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouteGuard } from  './../route.guard';

//\\import { SpinnerComponent } from './../shared/spinner/spinner.component';

import { SubscriptionService } from './subscription.service';
import { VerifySubscriptionComponent } from './verify-subscription/verify-subscription.component';

const routes: Routes = [
  {
    path: '',
    component: SubscriptionComponent,
    canActivate: [RouteGuard]
   },
   {
      path: 'add',
      component: NewsubscriptionComponent,
      canActivate: [RouteGuard],
    },
    {
      path: 'VerifySubscription',
      component: VerifySubscriptionComponent,
      canActivate: [RouteGuard],
    },
 
];

@NgModule({
  declarations: [
   NewsubscriptionComponent
  ,SubscriptionComponent, VerifySubscriptionComponent,
],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
//    BrowserAnimationsModule,
//    SpinnerComponent,
  ],
  providers:[
    SubscriptionService,
  ]
})
export class SubscriptionModule { }
