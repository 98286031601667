import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router} from '@angular/router';
import { Observable } from 'rxjs';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AuthenticationService } from './authentication/authentication.service';
import { SharedService } from './shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private sharedservice:SharedService,
    ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(!this.authService.isLoggedIn()){
        this.router.navigate(["/authentication/signin"]);
        return false;
      }

      if(!localStorage.getItem('info') || localStorage.getItem('info') == '' || localStorage.getItem('info') == undefined){
        this.sharedservice.GetUser().subscribe(response => {
          response = JSON.stringify(response);
          localStorage.setItem('info', response);
        },err => {
            //handle errors here
          console.log(err.error.Message);
        });
      }

    return true;
  }
  
}
