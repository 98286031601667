import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup,  FormBuilder,  Validators, NgForm } from '@angular/forms';
import 'rxjs/add/operator/first';
import { CustomValidators } from '../../shared/custom-validators';
import { ActivatedRoute } from '@angular/router';
import {HttpParameterCodec} from "@angular/common/http";

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  //forms:any = {};
  buttonDisable: any;
  forms: FormGroup;
  userId : any;
  token : any;
  minlengthError : any;
  
  constructor(
    private _service:AuthenticationService,
    private router:Router,
    private toastr:ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    ) {
    
   }

  ngOnInit(): void {
    this.buttonDisable = true;
    if(this._service.isLoggedIn()){
      this.router.navigate(["dashboard"]);
    }
    document.querySelector('.content-wrapper').classList.add('p-0');

    this.forms = this.formBuilder.group({
      newpassword: ['',  Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
       
        // check whether the entered password has a special character
       
        Validators.minLength(8)
      ])],
      cpassword: ['',  Validators.compose([Validators.required])]
    },{
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
    }
    );

    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'];
      this.token = decodeURI(params['token']);
      this.token = this.token.replace(/ /g, "+");
    });

  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
    return true;
  }

  changespass(forms){
    forms.value.UserId = this.userId;
    forms.value.Token = this.token;
    forms.value.NewPassword = forms.value.newpassword;
    this._service.resetPassword(forms.value).subscribe(response => {
        this.router.navigate(["/authentication/signin"])
    },err => {
        //handle errors here
        this.toastr.error(err.error.Message);

    })
  }
}
