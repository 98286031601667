import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment as Constants } from './../../environments/environment';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private url = '';

  constructor(private http: HttpClient) { 
    this.url = Constants.API_URL
  }

  getSubscription(price){
      return this.http.get<any>(this.url+"api/Subscription?contingent="+price).map(res => res);
  }
  getActiveSubsciption(){
    return this.http.get<any>(this.url+"api/Subscription/Active").map(res => res);
  }
  saveBilling(data){
    return this.http.post<any>(this.url+"api/Subscription/BillingInfo",data).map(res => res);
  }
  payPalSubscribe(data){
    return this.http.get<any>(this.url+"api/Subscription/Subscribe?subscriptionId="+data.subscriptionId).map(res => res);
  }
  VerifySubscription(data){
    return this.http.post<any>(this.url+"api/Subscription/VerifySubscription",data).map(res => res);
  }
}
