import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homefooter',
  templateUrl: './homefooter.component.html',
  styleUrls: ['./homefooter.component.scss']
})
export class HomefooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
