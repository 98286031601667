import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators ,FormControl, } from '@angular/forms';




@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  collections : any;
  images : any;
  isShow : boolean =false;

  currentPage = 1;
  itemsPerPage = 12;

  ngOnInit(): void {
    this.images = [
      {"img1":"assets/images/laptop-1564400518-5021074.jpeg",
      "img2":"assets/images/secondimage.png",
      "img3":"assets/images/iphon7_JetBlack.png",
      "img4":"assets/images/Amgen-Series-4-Smart-Watches-SDL172591234-1-5a25a.jpg"},


    ];
   
    this.collections = [
      { "id":1 ,"name":"Apple Products","description":"This means that your delivery drivers may ask for delivery","increment":true,"percent":"+15%","products":"12 products"},
      { "id":2 ,"name":"Electronics","description":"High quality products","increment":true ,"percent":"+2.4%","products":"64 products"},
      { "id":3 ,"name":"Beauty picks","description":"Only summer sale", "percent":"-3%","products":"32 products"},
      { "id":4 ,"name":"Home beddings","description":" ","increment":true,"percent":"+3.4%","products":"12 products" },
      { "id":5 ,"name":"Adidas","description":"Products above $100","percent":"-2.9%","products":"12 products" },
      { "id":6 ,"name":"Video Games","description":"Playstation and xBox" ,"increment":true,"percent":"+15%","products":"3 products"},
      { "id":1 ,"name":"Apple Products","description":"This means that your delivery drivers may ask for delivery ","increment":true,"percent":"+15%","products":"12 products"},
      { "id":2 ,"name":"Electronics","description":"High quality products","increment":true ,"percent":"+2.4%","products":"64 products"},
      { "id":3 ,"name":"Beauty picks","description":"Only summer sale", "percent":"-3%","products":"32 products"},
      { "id":4 ,"name":"Home beddings","description":"","increment":true,"percent":"+3.4%","products":"12 products" },
      { "id":5 ,"name":"Adidas","description":"Products above $100","percent":"-2.9%","products":"12 products" },
      { "id":6 ,"name":"Video Games","description":"Playstation and xBox" ,"increment":true,"percent":"+15%","products":"3 products"},
      { "id":6 ,"name":"Video Games","description":"Playstation and xBox" ,"increment":true,"percent":"+15%","products":"3 products"},
      { "id":1 ,"name":"Apple Products","description":"This means that your delivery drivers may ask for delivery ","increment":true,"percent":"+15%","products":"12 products"},
      { "id":2 ,"name":"Electronics","description":"High quality products","increment":true ,"percent":"+2.4%","products":"64 products"},
      { "id":3 ,"name":"Beauty picks","description":"Only summer sale", "percent":"-3%","products":"32 products"},
      { "id":4 ,"name":"Home beddings","description":"","increment":true,"percent":"+3.4%","products":"12 products" },
      { "id":5 ,"name":"Adidas","description":"Products above $100","percent":"-2.9%","products":"12 products" },
      { "id":6 ,"name":"Video Games","description":"Playstation and xBox" ,"increment":true,"percent":"+15%","products":"3 products"},
      { "id":6 ,"name":"Video Games","description":"Playstation and xBox" ,"increment":true,"percent":"+15%","products":"3 products"},
      { "id":1 ,"name":"Apple Products","description":"This means that your delivery drivers may ask for delivery ","increment":true,"percent":"+15%","products":"12 products"},
      { "id":2 ,"name":"Electronics","description":"High quality products","increment":true ,"percent":"+2.4%","products":"64 products"},
      { "id":3 ,"name":"Beauty picks","description":"Only summer sale", "percent":"-3%","products":"32 products"},
      { "id":4 ,"name":"Home beddings","description":"","increment":true,"percent":"+3.4%","products":"12 products" },
      { "id":5 ,"name":"Adidas","description":"Products above $100","percent":"-2.9%","products":"12 products" },
      { "id":6 ,"name":"Video Games","description":"Playstation and xBox" ,"increment":true,"percent":"+15%","products":"3 products"},
    ];
  }

  openSmallModal( smallModalContent ) {
    this.modalService.open( smallModalContent , { size : 'sm' } );
  }
 
}
