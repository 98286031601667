import { Component, OnInit } from '@angular/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from './../subscription.service';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
@Component({
  selector: 'app-verify-subscription',
  templateUrl: './verify-subscription.component.html',
  styleUrls: ['./verify-subscription.component.scss']
})
export class VerifySubscriptionComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private toastr:ToastrService,
    private service:SubscriptionService,
    private router: Router
  ) { }

  isLoading : boolean = true;
  UserId : any = '';
  Token : any= '';
  SubscriptionId:any= '';

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.UserId = params['UserId'];
      this.SubscriptionId = params['SubscriptionId'];
      this.Token = decodeURI(params['token']);
      this.Token = this.Token.replace(/ /g, "+");
    });

    this.VerifySubscription();
  }

  VerifySubscription(){
    var data : any = {};
    data.UserId = this.UserId;
    data.SubscriptionId = parseInt(this.SubscriptionId);
    data.Token = this.Token;
    this.service.VerifySubscription(data).subscribe(response => {
      this.router.navigate(["/subscription"]);
    },err => {
        //handle errors here
        this.toastr.error(err.error.Message);
    });
  }

}
