import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../subscription/subscription.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  constructor(
    private service: SubscriptionService,
    private toastr:ToastrService,
  ) { }

  subsciption:any;

  ngOnInit(): void {
    this.getActiveSubsciption();
  }

  getActiveSubsciption(){
    this.service.getActiveSubsciption().subscribe(response => {
      this.subsciption = response;
      this.subsciption.percent = (response.ContingentInUse / response.Contingent) * 100;
    },err => {
        //handle errors here
        this.toastr.error(err.error.Message);
    });
  }

}
