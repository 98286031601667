import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homenavbar',
  templateUrl: './homenavbar.component.html',
  styleUrls: ['./homenavbar.component.scss']
})
export class HomenavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
