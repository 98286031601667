import { Injectable } from '@angular/core';
import {HttpInterceptor,HttpRequest,HttpHandler,HttpEvent,HttpResponse,HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Subscription } from 'rxjs/Rx';
import { Router, ActivatedRoute } from '@angular/router';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private subscription: Subscription;
  constructor(
              private router:Router,
              private route:ActivatedRoute,
              ) { }
  
  intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    // add a custom header
    var customReq:any;
    if(!localStorage.auth || localStorage.auth == '' || localStorage.auth == undefined){
      customReq = request;
    }else{
      let data = JSON.parse(localStorage.user)
      let token = data.token_type+' '+localStorage.auth; 
      customReq = request.clone({headers: request.headers.set('Authorization', token)});
    }

    // pass on the modified request object
    return next.handle(customReq)
              .do((ev: HttpEvent<any>) => {
                if (ev instanceof HttpResponse) {
                  //let response = ev.body.result;
                  /* 
                  * code error
                  * 401 = unauthorized request
                  * 200 = success
                  * 500 = page not found
                  */
                  // switch(response.code){
                  //   case 401:
                  //     localStorage.removeItem('auth');
                  //     this.router.navigate(["authentication/signin"]);
                  //   break;
                  //   case 200:
                  //   break;
                  //   case 500:
                      
                  //   break;
                  // }
                }
              })
              .catch(response => {
                console.log(response,11)
                if (response instanceof HttpErrorResponse) {
                  if(response.status == 401){
                    localStorage.removeItem('auth');
                    localStorage.removeItem('user');
                    this.subscription = this.route.params.subscribe();
                    this.subscription.unsubscribe();
                    this.router.navigate(["/authentication/signin"])
                  }
                }
        
                return Observable.throw(response);
              });
    //return next.handle(request);
  }
 

}
